@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Libre+Baskerville:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Libre+Baskerville:wght@700&family=Miniver&family=Pattaya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Inter:wght@400;500&family=Libre+Baskerville:wght@700&family=Miniver&family=Pattaya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Inter:wght@400;500&family=Libre+Baskerville:wght@700&family=Miniver&family=Pattaya&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&family=Roboto+Mono:ital,wght@1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Knewave&family=PT+Serif:wght@700&family=Roboto+Mono:ital,wght@1,100&family=Water+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Modak&family=Sofadi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Modak&family=Sofadi+One&family=Truculenta:opsz,wght@12..72,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dhurjati&family=Modak&family=Monoton&family=Sofadi+One&family=Truculenta:opsz,wght@12..72,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Dhurjati&family=Modak&family=Monoton&family=Sofadi+One&family=Truculenta:opsz,wght@12..72,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Dhurjati&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Modak&family=Monoton&family=Sofadi+One&family=Truculenta:opsz,wght@12..72,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Dhurjati&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Modak&family=Monoton&family=Sofadi+One&family=Teko:wght@300..700&family=Truculenta:opsz,wght@12..72,100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Dancing+Script:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');



.snowflake {
  position: absolute;
  top: -10px;
  color: white;
  user-select: none;
  z-index: 2;
  animation-name: fall;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes fall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
}

#menu-list {
  font-family: "Fjalla One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

input[type="checkbox"] {
  position: absolute;
  display: none;
}

.menu-container {
  position: absolute;
  top: 130px;
  left: -30px;
  transform: translate(-50%, -50%);
  width: 200px; /* Ukuran menu bisa disesuaikan */
  height: 200px; /* Ukuran menu bisa disesuaikan */
}

.menu-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  background: #FF5722; /* Orange tua */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.6s cubic-bezier(.87, -.41, .19, 1.44);
}

.btn:not(:first-child) {
  opacity: 0;
  z-index: -2;
}

.btn:nth-child(1) { /* Menu Icon Button */
  z-index: 2;
}

.btn:nth-child(2) { /* Close Icon Button */
  transform: translateY(50px);
  opacity: 0;
  transition: all 0.3s ease;
}

input#toggle:checked ~ .menu-label .btn:nth-child(2) {
  transform: translateY(0);
  opacity: 1;
}

input#toggle:checked ~ .menu-label .btn:nth-child(1) {
  transform: translateY(-50px);
  opacity: 0;
}

input#toggle:checked ~ .menu-label .btn:nth-child(3) {
  top: 10%;
  left: 10%;
  opacity: 1;
}

input#toggle:checked ~ .menu-label .btn:nth-child(4) {
  top: 10%;
  left: 50%;
  opacity: 1;
}

input#toggle:checked ~ .menu-label .btn:nth-child(5) {
  top: 50%;
  left: 10%;
  opacity: 1;
}

input#toggle:checked ~ .menu-label .btn:nth-child(6) {
  top: 50%;
  left: 50%;
  opacity: 1;
}

input#toggle:checked ~ .menu-label .btn:nth-child(7) {
  top: 90%;
  left: 10%;
  opacity: 1;
}

input#toggle:checked ~ .menu-label .btn:nth-child(8) {
  top: 90%;
  left: 50%;
  opacity: 1;
}

.icon-button {
  background: transparent; /* Menghapus latar belakang tombol untuk menampilkan latar belakang btn */
  margin-top: 200px;
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes imageBreakAndJoin {
  0% {
    transform: scale(1) translateX(0) translateY(0);
    clip-path: inset(0 0 0 0);
  }
  25% {
    transform: scale(1.1) translateX(-10px) translateY(-10px);
    clip-path: inset(0 0 0 0);
  }
  50% {
    transform: scale(1) translateX(0) translateY(0);
    clip-path: inset(20% 20% 20% 20%); /* Simulasi pecah */
  }
  75% {  
    transform: scale(1.1) translateX(10px) translateY(10px);
    clip-path: inset(0 0 0 0);
  }
  100% {
    transform: scale(1) translateX(0) translateY(0);
    clip-path: inset(0 0 0 0); /* Gambar menyatu kembali */
  }
}

.product-text {
  font-family: 'Merriweather', serif;
}

.product-text-script {
  font-family: 'Dancing Script', cursive;
}

.wave-animation {
  animation: wave 2s infinite;
}

.image-container {
  position: relative;
  text-align: center;
}

.centered-text { 
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-weight: bold;
  color: white;
}


.favorite-flag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #BF953F;
  color: white;
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 2; /* Menyesuaikan z-index agar elemen Favorite tampil di depan */
}

.stars::before {
  content: "★";
  position: absolute;
  top: -20px;
  left: -10px;
  font-size: 30px; /* Ukuran bintang */
  color: #FFD700; /* Warna bintang */
  animation: glowEffect 2s infinite alternate; /* Efek kedip */
}

@keyframes glowEffect {
  0% {
    opacity: 0; /* Efek glow saat opacity 0 */
  }
  100% {
    opacity: 1; /* Efek glow saat opacity 1 */
  }
}



#produk-kami {
  font-family: "Dhurjati", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#text-header {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: italic;
  color: white;
}

#text-header-produk {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
  color: orange;
}

@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}




.flip-on-hover:hover {
  transform: rotateY(180deg); /* Membuat flip horizontal pada hover */
}

#text-header-kiri-kanan {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
  color: orange;
  position: relative;
}

#text-event-card {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
  position: relative;
}

#text-event {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
  position: relative;
}

#text-header-kiri-kanan::after {
  content: "";
  position: absolute;
  top: -100%; /* Memulai dari atas */
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 70%);
  transform: skewY(-15deg);
  animation: shine 2s infinite;
}

@keyframes shine {
  0% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}

#text-header-artikel {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: italic;
  color: white;
}

#deskripsi-produk-kami {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
  color: white;
}

#deskripsi-produk-kami-2 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
  color: black;
}

#deskripsi-produk-kami-3 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
  color: white;
}

#deskripsi-form {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
  color: black;
}

#title-info {
  font-family: "Edu NSW ACT Foundation", cursive;
  font-style: normal;
}

#deskripsi-info {
  font-family: "Edu NSW ACT Foundation", cursive;
  font-optical-sizing: auto;
  font-style: normal;
  color: white;
}

.type-text {
  margin-top: "300px";
  margin-bottom: "40px";
  color: "black";
  font-style: "italic";
  font-family: "Montserrat", sans-serif;
}

@keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.year-text {
  font-family: 'Pacifico', cursive; /* Ganti dengan jenis font yang diinginkan */
  color: #e91e63; /* Ganti dengan warna yang diinginkan */
  animation: bounceIn 2s infinite; /* Sesuaikan durasi dan jenis animasi sesuai kebutuhan */
}

@keyframes ringing {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes moveButton {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  100% {
    transform: translateX(-50%) translateY(-5px);
  }
}

@keyframes wavingFlagAnimation {
  0% {
    transform: translateX(0) rotate(-5deg);
  }
  50% {
    transform: translateX(5px) rotate(5deg);
  }
  100% {
    transform: translateX(0) rotate(-5deg);
  }
}

@keyframes floatText {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}



@keyframes cloudAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

:where(.css-pr0fja).ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  height: 50px;
  width: 50px;
}

.category-button-container {
  display: flex;
  overflow: hidden;
}

.category-button-row {
  display: flex;
  /* animation: scrollButtons 10s linear infinite; */
  /* Adjust the duration as needed */
}

#kategori-artikel {
  font-family: "Dhurjati", sans-serif;
  color: #2b7d7d;
}

.category-button {
  margin: 10px;
  height: 50px;
  font-size: 12px;
  text-align: left;
  /* background: linear-gradient(90deg, #FFD700 0%, #FFA500 100%); */
  color: white;
  font-weight: bold;
  text-transform: none;
}



.category-icon {
  width: 40px;
  height: 40px;
  padding: 2px;
}

@keyframes scrollButtons {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-300px * 4); /* Sesuaikan jumlah tombol */
  }
}


.ant-btn {
  background-color: orange;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button {
  height: 50px;
  width: 50px;
}

.ant-btn-icon {
  color: white;
}

.ant-input {
  height: 50px;
}

.cloud {
  background: radial-gradient(circle, #fff 20%, transparent 20%),
    radial-gradient(circle, #fff 20%, transparent 20%),
    radial-gradient(circle, #fff 20%, transparent 20%),
    radial-gradient(circle, #fff 20%, transparent 20%),
    radial-gradient(circle, #fff 20%, transparent 20%);
  background-position: 0 0, 25px 0, 50px 0, 75px 0, 100px 0;
  background-size: 20px 20px;
  background-repeat: repeat-x;
  height: 100%;
}

.snowfall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
}

.snowflake {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px; /* Sesuaikan ukuran snowflake */
  height: 20px;
  animation: fall 10s linear infinite;
}

.snowflake-particle {
  display: block;
  background: #fff; /* Warna butiran salju */
  width: 2px; /* Sesuaikan ukuran butiran salju */
  height: 2px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}





.App {
  text-align: center;
}

@keyframes underlineAnimation {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes rollLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}



@keyframes rollRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

/* .shopee-icon {
  max-width: 100%;
  color: #e6282b;
  width: 70px;
  height: 50px;
  position: fixed;
  bottom: 33px;
  left: 20px;
  animation: ringJumpAnimation 1.5s ease-in-out infinite;
} */

/* @keyframes ringJumpAnimation {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) scale(1);
  }
  40% {
    transform: translateY(-30px) scale(1.2);
  }
  60% {
    transform: translateY(-15px) scale(0.8);
  }
} */

/* .tokped-icon {
  max-width: 100%;
  color: #e6282b;
  width: 70px;
  height: 50px;
  position: fixed;
  bottom: 33px;
  left: 20px;
  animation: ringJumpAnimation 1.5s ease-in-out infinite;
} */







.shopee-icon, .tokped-icon {
  position: fixed;
  bottom: 33px;
  width: 70px;
  height: 50px;
}

.shopee-icon {
  animation: ringJumpAnimation 1.5s ease-in-out infinite;
  color: #e6282b;
}

.tokped-icon {
  animation: ringJumpAnimation 1.5s ease-in-out infinite;
  color: #e6282b;
}

@keyframes ringJumpAnimation {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) scale(1);
  }
  40% {
    transform: translateY(-30px) scale(1.2);
  }
  60% {
    transform: translateY(-15px) scale(0.8);
  }
}




.product-card {
  /* Gaya kartu yang tidak berputar */
  /* ... Penyesuaian lainnya */

  transition: transform 0.5s ease-in-out;
}

.flipped-card {
  transform: rotateY(180deg);
}



.normal-content {
  display: block;
}

.flipped-content {
  display: none;
}

.card-container.card-flipped .normal-content {
  display: none;
}

.card-container.card-flipped .flipped-content {
  display: block;
}






/* Terapkan animasi pada elemen-elemen */
.scroll-container {
  overflow: hidden;
}

.scroll-content {
  display: flex;
  animation: scroll 10s linear infinite; /* Ubah durasi animasi sesuai kebutuhan */
}

.scroll-item {
  margin-right: 100px; /* Jarak antara elemen-elemen */
}

.flip-card {
  width: 200px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flip-card.is-flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}




.App-logo {
  height: 40vmin;
  color: antiquewhite;
  pointer-events: none;
}

#title-header-1 {
  text-align: center;
  font-family: 'Pattaya', sans-serif;
  color: white;
}

#cap {
  font-family: 'Inter', sans-serif;
}

#hover-menu:hover {
  background: linear-gradient(90deg, rgba(227,218,31,1) 0%, rgba(255,192,0,1) 93%);
  color: white;
}


#title-header {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: white;
}


#button-header {
  margin-top: "20px";
  border-radius: "20px";
}

#button-header:hover {
  border-color: orange;
}


#review {
  margin-top: 20px;
  width: 100px;
  text-align: center;
}


#title-produk {
  font-family: 'Knewave';
}

.app-bar {
  /* background: linear-gradient(90deg, rgba(230,191,13,1) 43%, rgb(221, 202, 29) 100%); */
  flex-direction: 'row'; 
  justify-content: 'space-between'; 
  background-color: 'transparent !important';
  backdrop-filter: 'blur(10px)';
  box-shadow: 'none' 
}

#footer {
  background: linear-gradient(90deg, rgba(230,191,13,1) 4%, rgba(236,234,216,1) 100%);
}

.logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Adjust the margin as needed */
}

.ant-card-body {
  width: 300px;
}

.logo-container img {
  margin-right: 10px; /* Adjust the margin as needed */
}

.logo-text {
  font-family: 'Arial', sans-serif; /* Use your desired font */
  font-size: 36px; /* Adjust the font size as needed */
  font-weight: bold;
  color: #008000; /* Green color */
  text-transform: uppercase;
  letter-spacing: 4px; /* Adjust letter spacing */
  text-align: center;
}

.logo-text-2 {
  margin-left: 20px;
  font-family: 'Arial', sans-serif; /* Use your desired font */
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: bold;
  color: #008000; /* Green color */
  text-transform: uppercase;
  letter-spacing: 4px; /* Adjust letter spacing */
  text-align: center;
}

.gunung-text {
  color: #FF0000; /* Red color for "Gunung" */
  font-family: 'PT Serif', serif;
  font-size: 17.5px;
}

.titleProduk {
  font-weight: 'bold';
  padding-top: '10px';
  font-family: "Lato", sans-serif;
  position: 'relative',
}



.description-produk {
  font-family: "Monserrat", sans-serif;
}

#description-artikel {
  font-family: "Truculenta", sans-serif;
}

#review:hover {
  background-color: #e6bf0d;
  color: white;
}

#kembali:hover {
  background-color: #e6bf0d;
  color: white;
}

.ant-dropdown-link {
  color: black;
  text-decoration: none;
}

.Typewriter {
  font-size: 20px;
  font-family: cursive;
  color: aliceblue;
}

.footer {
  background-color: azure;
}


/* #home:hover {
  background-color: #282c34;
  width: 60px;
  height: 60px;
} */



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
